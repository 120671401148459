"use client";
import { usePathname, useRouter } from "@arkham/i18n";
import { HoverPopup } from "@arkham/ui-components";
import { useLocale } from "next-intl";
import { useCallback, useState } from "react";
import { MdLanguage } from "react-icons/md";
import { PopupContainer } from "./PopupContainer";

export function LanguageSwitcher({ className }: { className?: string }) {
    const locale = useLocale();
    const router = useRouter();
    const pathname = usePathname();

    const changeLanguage = useCallback(
        (newLocale: string) => {
            router.replace(pathname, { locale: newLocale });
        },
        [router, pathname],
    );

    const languages = [
        { code: "en", name: "English" },
        { code: "zh", name: "中文" },
    ];

    const [open, setOpen] = useState(false);

    const LanguagePopup = ({ closePopup }: { closePopup: () => void }) => (
        <PopupContainer className="flex-col px-3 py-2">
            {languages.map((lang) => (
                <button
                    key={lang.code}
                    className={`hover-blue underline-none rounded-sm px-2 py-1.5`}
                    onClick={() => {
                        changeLanguage(lang.code);
                        closePopup();
                    }}
                >
                    <div
                        className={`flex w-full items-center justify-between gap-6 ${locale === lang.code ? "text-blue-900" : ""}`}
                    >
                        <span className={`text-sm`}>{lang.name}</span>
                        <span className="text-sm uppercase">{lang.code}</span>
                    </div>
                </button>
            ))}
        </PopupContainer>
    );

    return (
        <HoverPopup
            open={open}
            onOpenChange={setOpen}
            children={
                <button className={`${className} ${open ? "text-blue-900" : ""}`}>
                    <MdLanguage className="scale-110" />
                </button>
            }
            popup={LanguagePopup}
            alignment={20}
            placement="bottom-end"
        />
    );
}
