"use client";
import {
    FloatingFocusManager,
    FloatingPortal,
    Placement,
    autoUpdate,
    offset,
    safePolygon,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useTransitionStyles,
} from "@floating-ui/react";
import React, { CSSProperties, FunctionComponent } from "react";

// INTENDED BEHAVIOR:
// when tabbing, if you close the popup with escape key, the focus should return to the trigger element
// when clicking outside to dismiss after tab open, the focus should NOT return to the trigger element
// if you change this component check for bug where you tab into focus, click outside,
// then mouseover a few other popup triggers and it refocuses the first trigger

export const HoverPopup: FunctionComponent<{
    open: boolean;
    onOpenChange: (nextOpen: boolean) => void;
    children: React.ReactNode;
    popup: (props: { style?: CSSProperties; closePopup: () => void }) => React.ReactNode;
    alignment?: number;
    mainAxisOffset?: number;
    placement?: Placement;
    shiftPadding?: number;
}> = ({
    open,
    onOpenChange,
    children,
    popup,
    alignment = 0,
    mainAxisOffset = 16,
    shiftPadding = 5,
    placement = "bottom-start",
}) => {
    const { refs, floatingStyles, context } = useFloating({
        open,
        onOpenChange: (nextOpen, event, reason) => {
            if (reason === "escape-key") {
                if (refs.reference.current && refs.reference.current instanceof HTMLElement) {
                    const firstChild = refs.reference.current.children[0];
                    if (firstChild instanceof HTMLElement) {
                        firstChild.focus();
                    }
                }
            }

            onOpenChange(nextOpen);
        },
        placement,
        middleware: [offset({ mainAxis: mainAxisOffset, crossAxis: alignment }), shift({ padding: shiftPadding })],
        whileElementsMounted: autoUpdate,
    });

    const dismiss = useDismiss(context, {
        outsidePress: true,
        escapeKey: true,
    });

    const hover = useHover(context, {
        handleClose: safePolygon({
            requireIntent: false,
            blockPointerEvents: true,
        }),
        move: false,
    });

    const focus = useFocus(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss]);

    const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
        duration: 100,
    });

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </div>

            {isMounted && (
                <FloatingPortal>
                    <FloatingFocusManager
                        context={context}
                        modal={false}
                        returnFocus={false} // We'll handle focus return manually
                        initialFocus={-1}
                    >
                        <div
                            className="z-50 text-xs"
                            ref={refs.setFloating}
                            style={{ ...floatingStyles, ...transitionStyles }}
                            {...getFloatingProps()}
                        >
                            {popup({
                                style: transitionStyles,
                                closePopup: () => onOpenChange(false),
                            })}
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </>
    );
};
