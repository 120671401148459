import { ComponentProps } from "react";
import styles from "./Button.module.css";
import { Logo } from "./Logo";

export const BUTTON_VARIANTS = [
    "primary",
    "secondary",
    "tertiary",
    "alpha",
    "secondary-alpha",
    "alpha-tertiary",
] as const;

export const BUTTON_COLORS = ["grey", "green", "blue", "red"] as const;
export const BUTTON_SIZES = ["xs", "sm", "md", "lg"] as const;

export function Button({
    color = "grey",
    variant = "primary",
    size = "md",
    disabled = false,
    "data-testid": dataTestId,
    isLoading = false,
    ...props
}: {
    color?: (typeof BUTTON_COLORS)[number];
    variant?: (typeof BUTTON_VARIANTS)[number];
    size?: (typeof BUTTON_SIZES)[number];
    disabled?: boolean;
    "data-testid"?: string;
    isLoading?: boolean;
} & ComponentProps<"button">) {
    const classes = [styles.button];
    if (color) classes.push(styles[color]);
    if (variant) {
        if (variant === "secondary-alpha") {
            classes.push(styles.alphaSecondary);
        } else if (variant === "alpha-tertiary") {
            classes.push(styles.alphaTertiary);
        } else {
            classes.push(styles[variant]);
        }
    }

    if (size) {
        classes.push(styles[size]);
    }

    if (props.className) classes.push(props.className);
    return (
        <button
            {...props}
            data-testid={dataTestId}
            disabled={disabled || isLoading}
            style={{ ...props.style }}
            className={classes.join(" ")}
        >
            {isLoading ? (
                <div className="flex items-center justify-center">
                    <Logo of="loadingIcon" className="h-5 w-5" />
                </div>
            ) : (
                props.children
            )}
        </button>
    );
}
