import { Link } from "@arkham/i18n";
import styles from "./Tabs.module.css";

type Color = "green" | "red" | "blue";

interface TabItem<T extends string> {
    id: T;
    name?: string;
    color?: Color;
    breakpoint?: "sm" | "md" | "lg" | "xl" | "2xl";
    href?: string;
}

interface TabsProps<T extends string> {
    tabs: TabItem<T>[];
    tab: T;
    setTab?: (tab: T) => void;
    textTransform?: "uppercase" | "capitalize";
    showBorder?: boolean;
    widthClasses?: string;
    className?: string;
    textAlignClasses?: string;
}

export function Tabs<T extends string>({
    tabs,
    tab,
    setTab,
    textTransform = "capitalize",
    showBorder = true,
    widthClasses = "w-auto",
    className,
    textAlignClasses = "left",
}: TabsProps<T>) {
    return (
        <div
            className={`relative inline-flex shrink-0 overflow-x-auto ${styles.scrollContainer} ${showBorder ? styles.withBorder : ""} ${className}`}
            style={{ textTransform: textTransform }}
            role="tablist"
            aria-orientation="horizontal"
        >
            {tabs.map((loopTab) => {
                const isActive = tab === loopTab.id;
                const activeColor = loopTab.color ?? "blue";
                const tabClassName = `${styles.tab} ${
                    isActive ? `${styles[activeColor]} ${styles.activeTab}` : ""
                } ${widthClasses}`;
                const breakpointClass = loopTab.breakpoint ? `hidden ${loopTab.breakpoint}:inline` : "inline";

                if (loopTab.href) {
                    return (
                        <Link
                            href={loopTab.href}
                            className={`${tabClassName} ${breakpointClass} ${textAlignClasses}`}
                            key={loopTab.id}
                            role="tab"
                            aria-selected={isActive}
                            aria-controls={`panel-${loopTab.id}`}
                            id={`tab-${loopTab.id}`}
                        >
                            {loopTab.name ?? loopTab.id}
                        </Link>
                    );
                }

                return (
                    <button
                        onClick={() => setTab?.(loopTab.id)}
                        className={`${tabClassName} ${breakpointClass} ${textAlignClasses}`}
                        key={loopTab.id}
                        role="tab"
                        aria-selected={isActive}
                        aria-controls={`panel-${loopTab.id}`}
                        id={`tab-${loopTab.id}`}
                    >
                        {loopTab.name ?? loopTab.id}
                    </button>
                );
            })}
        </div>
    );
}
