import styles from "./Toggle.module.css";

type ToggleSize = "sm" | "md" | "lg";

export const Toggle = ({
    checked,
    onChange,
    className = "",
    disabled = false,
    size = "md",
}: {
    checked: boolean;
    onChange: () => void;
    className?: string;
    disabled?: boolean;
    size?: ToggleSize;
}) => {
    const sizeClasses = {
        sm: styles.sm,
        md: styles.md,
        lg: styles.lg,
    };

    return (
        <label className={`${styles.switch} ${sizeClasses[size]} ${className}`}>
            <input type="checkbox" checked={checked} role="switch" onChange={onChange} disabled={disabled} />
            <span className={styles.slider}></span>
        </label>
    );
};
