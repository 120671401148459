"use client";
import React, { ComponentProps, KeyboardEvent, useRef, useState } from "react";

type InputProps = {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    label?: string;
    secondaryLabel?: string;
    error?: string;
    showErrorBeforeBlur?: boolean;
    type?: "opaque" | "transparent";
    inputType?: string;
    className?: string;
    onEnterPress?: () => void;
} & Omit<ComponentProps<"input">, "type">;

export function Input({
    leftIcon,
    rightIcon,
    label,
    secondaryLabel,
    error,
    showErrorBeforeBlur = false,
    type = "opaque",
    inputType = "text",
    className,
    onEnterPress,
    ...props
}: InputProps) {
    const [isFocused, setIsFocused] = useState(false);
    const [hasBlurred, setHasBlurred] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        setHasBlurred(true);
        if (props.onBlur) props.onBlur(e);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && onEnterPress && !props.disabled) {
            e.preventDefault();
            onEnterPress();
        }
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    };

    // Handle clicking anywhere in the container
    const handleContainerClick = () => {
        if (inputRef.current && !props.disabled) {
            inputRef.current.focus();
        }
    };

    const showError = error && (hasBlurred || showErrorBeforeBlur);

    return (
        <div className={`flex flex-col gap-1 ${className}`}>
            {(label || secondaryLabel) && (
                <div className="flex w-full flex-row items-center justify-between text-xs">
                    {label && <div className={showError ? "text-red-700" : "text-white-1000"}>{label}</div>}
                    {secondaryLabel && <div className="text-white-600 font-light">{secondaryLabel}</div>}
                </div>
            )}

            <div
                className={`flex flex-row items-center justify-between gap-2 rounded-[0.3125rem] border px-3 py-[0.625rem] transition-colors duration-100 ease-in-out ${
                    props.disabled
                        ? type === "opaque"
                            ? "bg-slate-blue-500 border-slate-blue-700 cursor-not-allowed"
                            : "bg-white-50 border-white-100 cursor-not-allowed"
                        : type === "opaque"
                          ? "bg-slate-blue-300 cursor-text"
                          : "bg-black-100 cursor-text"
                } ${
                    showError
                        ? "border-red-600"
                        : isFocused
                          ? "border-blue-600"
                          : !props.disabled && "border-slate-blue-800 hover:border-slate-blue-1200"
                }`}
                onClick={handleContainerClick}
            >
                {leftIcon ? (
                    <div className={`flex items-center justify-center ${!props.disabled ? "cursor-text" : ""}`}>
                        {leftIcon}
                    </div>
                ) : (
                    <div></div>
                )}
                <input
                    ref={inputRef}
                    className={`placeholder:text-white-500 w-full border-0 bg-transparent text-base outline-none focus:border-0 focus:shadow-none focus:ring-0 focus:outline-none md:text-sm ${
                        props.disabled ? "cursor-not-allowed" : "cursor-text"
                    }`}
                    type={inputType}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    style={{ WebkitAppearance: "none" }}
                    {...props}
                />
                {rightIcon ? (
                    <div className={`flex items-center justify-center ${!props.disabled ? "cursor-pointer" : ""}`}>
                        {rightIcon}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>

            {showError && (
                <div className="rounded-[0.3125rem] bg-red-200 px-[0.375rem] py-1 text-xs text-red-900">{error}</div>
            )}
        </div>
    );
}
