"use client";

// import unscramblingIconStaticWord from "./_assets/ARKHAM-headerStaticText.json";
// import unscramblingIconUnscramblingWord from "./_assets/ArkhamHover2.json";
// import blinkingIcon from "./_assets/ArkhamLogo_BlinkOnlyLOTTIE.json";
import lookingAndBlinkingIcon from "./_assets/ArkhamLogo_Look+Blink_LOTTIE.json";
// import lookingIcon from "./_assets/ArkhamLogo_NoBlinkLOTTIE.json";
import { useLottie } from "lottie-react";
import { ComponentProps, useEffect } from "react";
import loadingIcon from "./_assets/Loader.json";
import lookingAndBlinkingIconStaticWord from "./_assets/arkham_blink+text.json";

const lottieFiles = {
    loadingIcon,
    // unscramblingIconUnscramblingWord,
    // unscramblingIconStaticWord,
    // blinkingIcon,
    // lookingIcon,
    lookingAndBlinkingIcon,
    lookingAndBlinkingIconStaticWord,
};

export const Logo = ({
    of,
    className,
    maxHeight,
    stopped,
    ...props
}: {
    className?: string;
    maxHeight?: number | string;
    of:
        | "staticIcon"
        //  "staticIconStaticWord" |
        | keyof typeof lottieFiles;
    stopped?: boolean;
} & ComponentProps<"div">) => {
    const Lottie = useLottie({
        animationData:
            // of === "staticIconStaticWord"
            //     ? unscramblingIconStaticWord
            //     :

            of === "staticIcon" ? loadingIcon : lottieFiles[of],
        initialSegment: of.startsWith("unscrambling") ? [2, 30] : undefined,
        onEnterFrame: of.startsWith("unscrambling")
            ? (data) => {
                  const { currentTime, direction, totalTime } = data as unknown as {
                      currentTime: number;
                      direction: number;
                      totalTime: number;
                  }; // the library is badly typed
                  if (Math.floor(currentTime) === 0 && direction === -1) Lottie.setDirection(1);
                  if (Math.ceil(currentTime) === totalTime && direction === 1) Lottie.setDirection(-1);
              }
            : undefined,
    });

    useEffect(() => {
        if (
            of === "staticIcon"
            // || of === "staticIconStaticWord"
        )
            Lottie.goToAndStop(0);
        else if (of.startsWith("unscrambling")) {
            Lottie.setDirection(-1);
            Lottie.goToAndPlay(30);
        } else {
            Lottie.setDirection(1);
            Lottie.goToAndPlay(0);
        }
    }, [of]);

    useEffect(() => {
        if (stopped) Lottie.stop();
        else if (stopped === false) Lottie.play();
    }, [stopped]);

    return (
        <div {...props} className={`flex min-h-0 justify-center ${className || ""}`} style={{ maxHeight }}>
            {Lottie.View}
        </div>
    );
};
