import * as SelectPrimitive from "@radix-ui/react-select";
import * as React from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

export interface SelectOption<T = string> {
    value: T;
    label?: string;
    disabled?: boolean;
    render?: (option: SelectOption<T>) => React.ReactNode;
}

interface SelectProps<T = string> {
    options: SelectOption<T>[];
    value?: T;
    onValueChange?: (value: string) => void;
    placeholder?: string;
    renderValue?: (value: T, options: SelectOption<T>[]) => React.ReactNode;
    disabled?: boolean;
    label?: string;
    error?: string;
    type?: "opaque" | "transparent";
    onEmpty?: React.ReactNode;
    className?: string;
}

export function Select<T = string>({
    options,
    value,
    onValueChange,
    placeholder = "Select an option",
    renderValue,
    disabled,
    label,
    error,
    type = "opaque",
    onEmpty,
    className,
}: SelectProps<T>) {
    const selectedOption = value !== undefined ? options.find((opt) => opt.value === value) : undefined;

    return (
        <div className={`flex flex-col gap-1 ${className}`} data-error={!!error}>
            {label && (
                <div className="flex w-full flex-row items-center justify-between text-xs font-normal">
                    <div className={error ? "text-red-700" : "text-white-900"}>{label}</div>
                </div>
            )}

            <SelectPrimitive.Root
                value={value !== undefined ? String(value) : undefined}
                onValueChange={onValueChange}
                disabled={disabled}
            >
                <SelectPrimitive.Trigger
                    className={`group flex w-full flex-row items-center justify-between gap-2 rounded-[0.3125rem] border px-[0.625rem] py-3 transition-colors duration-100 ease-in-out outline-none ${type === "opaque" ? "bg-slate-blue-300" : "bg-black-100"} data-[disabled=true]:cursor-not-allowed ${type === "opaque" ? "data-[disabled=true]:bg-slate-blue-500 data-[disabled=true]:border-slate-blue-700" : "data-[disabled=true]:bg-white-50 data-[disabled=true]:border-white-100"} ${error ? "border-red-600" : "border-slate-blue-800 hover:border-slate-blue-1200"} focus-visible:ring-opacity-50 focus-visible:border-blue-600 focus-visible:ring focus-visible:ring-blue-300 data-[state=open]:border-blue-600`}
                    data-disabled={disabled}
                >
                    <SelectPrimitive.Value
                        placeholder={<span className="text-white-500">{placeholder}</span>}
                        className="flex-1 text-left"
                    >
                        {selectedOption &&
                            (renderValue
                                ? renderValue(selectedOption.value, options)
                                : selectedOption.render
                                  ? selectedOption.render(selectedOption)
                                  : selectedOption.label || String(selectedOption.value))}
                    </SelectPrimitive.Value>

                    <SelectPrimitive.Icon>
                        <BsChevronDown className="text-white-1100 text-sm transition-transform duration-150 ease-linear group-focus-visible:text-blue-700 group-data-[state=open]:rotate-x-180 group-data-[state=open]:text-blue-700" />
                    </SelectPrimitive.Icon>
                </SelectPrimitive.Trigger>

                <SelectPrimitive.Portal>
                    <SelectPrimitive.Content
                        position="popper"
                        sideOffset={5}
                        collisionPadding={8}
                        className={`border-slate-blue-800 ${
                            type === "opaque" ? "bg-slate-blue-300" : "bg-ash-800 backdrop-blur-xl"
                        } animate-in fade-in-0 relative z-50 max-h-[var(--radix-select-content-available-height)] w-[var(--radix-select-trigger-width)] overflow-hidden rounded-md border opacity-0 shadow-md duration-150 data-[state=open]:opacity-100`}
                    >
                        <SelectPrimitive.ScrollUpButton className="bg-slate-blue-300 hover:bg-white-100 text-white-1100 flex h-6 cursor-default items-center justify-center">
                            <BsChevronUp size={16} />
                        </SelectPrimitive.ScrollUpButton>

                        <SelectPrimitive.Viewport className="p-1">
                            {options.length > 0 ? (
                                options.map((option) => (
                                    <SelectPrimitive.Item
                                        key={String(option.value)}
                                        value={String(option.value)}
                                        disabled={option.disabled}
                                        className={`data-[highlighted]:bg-white-100 hover:bg-white-100 relative flex w-full cursor-default items-center rounded-sm px-2 py-1.5 text-sm outline-none select-none data-[disabled]:opacity-50 data-[state=checked]:font-medium`}
                                    >
                                        {option.render ? (
                                            option.render(option)
                                        ) : (
                                            <SelectPrimitive.ItemText>
                                                {option.label || String(option.value)}
                                            </SelectPrimitive.ItemText>
                                        )}
                                    </SelectPrimitive.Item>
                                ))
                            ) : (
                                <SelectPrimitive.Item
                                    value="empty-state"
                                    disabled
                                    className="relative flex w-full cursor-default items-center rounded-sm px-8 py-1.5 text-sm opacity-70 outline-none select-none"
                                >
                                    <SelectPrimitive.ItemText>
                                        {onEmpty || "No options available"}
                                    </SelectPrimitive.ItemText>
                                </SelectPrimitive.Item>
                            )}
                        </SelectPrimitive.Viewport>

                        <SelectPrimitive.ScrollDownButton className="bg-slate-blue-300 hover:bg-white-100 text-white-1100 flex h-6 cursor-default items-center justify-center">
                            <BsChevronDown size={16} />
                        </SelectPrimitive.ScrollDownButton>
                    </SelectPrimitive.Content>
                </SelectPrimitive.Portal>
            </SelectPrimitive.Root>

            {error && (
                <div className="rounded-[0.3125rem] bg-red-200 px-[0.375rem] py-1 text-xs text-red-900">{error}</div>
            )}
        </div>
    );
}
