"use client";

import React, { ReactNode, createContext, useContext } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiInfoCircle } from "react-icons/bi";
import { CiWarning } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { MdCheck } from "react-icons/md";
import { ToastOptions as ReactToastifyOptions, ToastContainer, toast } from "react-toastify";

type ToastType = "success" | "error" | "warning" | "info";

interface ToastOptions extends Omit<ReactToastifyOptions, "type"> {
    type?: ToastType;
}

const ToastContext = createContext<((message: ReactNode, options?: ToastOptions) => void) | undefined>(undefined);

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error("useToast must be used within a ToastProvider");
    }
    return context;
};

const iconStyle = { width: "0.9rem", height: "0.9rem", color: "inherit" };

const icons: Record<ToastType, ReactNode> = {
    success: <MdCheck style={iconStyle} />,
    error: <AiOutlineExclamationCircle style={iconStyle} />,
    warning: <CiWarning style={iconStyle} />,
    info: <BiInfoCircle style={iconStyle} />,
};

const typeClasses: Record<ToastType, string> = {
    success: "!border-green-1000 !bg-green-a200 !text-green-1000",
    error: "!border-red-1000 !bg-red-a200 !text-red-1000",
    warning: "!border-yellow-1000 !bg-yellow-a200 !text-yellow-1000",
    info: "!border-blue-1000 !bg-blue-200 !text-blue-1000",
};

function ToastContent({ message, icon }: { message: ReactNode; icon?: ReactNode }) {
    return (
        <div className="mr-2 flex w-full items-center gap-2 font-sans text-sm">
            {icon && <span className="flex-shrink-0">{icon}</span>}
            {message}
        </div>
    );
}

const CloseButton = ({ closeToast }: { closeToast: () => void }) => {
    return <IoMdClose onClick={closeToast} className="text-white-800 hover:text-white-900 flex-shrink-0 self-start" />;
};

export function ToastProvider({ children }: { children: ReactNode }) {
    const showToast = (message: ReactNode, options: ToastOptions = {}) => {
        const toastType = options.type as ToastType;
        toast(<ToastContent message={message} icon={icons[toastType]} />, {
            ...options,
            icon: false,
            className: `!p-2 !pr-3 !min-h-0 !border !backdrop-blur-xl ${typeClasses[toastType]}`,
        });
    };

    return (
        <ToastContext.Provider value={showToast}>
            {children}
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={CloseButton}
            />
        </ToastContext.Provider>
    );
}
